var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "select",
      {
        staticClass: "custom-select",
        attrs: { required: "" },
        domProps: { value: _vm.value },
        on: {
          input: function($event) {
            return _vm.$emit("input", $event.target.value)
          }
        }
      },
      [
        _c("option", { attrs: { value: "未回答", selected: "" } }, [
          _vm._v("---未回答---")
        ]),
        _vm._l(_vm.items, function(item) {
          return _c("option", { domProps: { value: item } }, [
            _vm._v(_vm._s(item))
          ])
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }